.root {
    all: unset;
    position: relative;
    display: flex;
    background: #eee;
    height: 87vh;
}

.sideBarImageArea {
    grid-area: image;
    border-radius: 16px;
    width: 88px;
    height: 88px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

}


.birthArea {
    font-family: Golos Text;
    color: #7F7F7F;
    grid-area: birth;
    font-size: 12px;
    margin: 8px 0px 0px;
}

.birthAreaForDead {
    font-family: Golos Text;
    color: #7F7F7F;
    grid-area: birth;
    font-size: 12px;
    margin: 4px 0px 16px;
}

.relationAreaForDead {
    font-family: Golos Text;
    color: #7F7F7F;
    grid-area: birth;
    font-size: 12px;
    margin: 4px 0px 0px 0px;
    line-height: 15.6px;
}


.headerButton {
    flex-direction: column;
    border-radius: 16px;
    font-size: 12px;
    line-height: 15.6px;
    font-weight: 500;
    font-family: 'Golos Text';
}

.title {
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-weight: 500;
}

.wrapper {
    flex: 1;
    background: #F0F0F0;

}

.tree {
    background: #fff;
    border: 5px solid #ddd;
    border-radius: 4px;
    background: salmon;
}

.staticPosition {
    position: absolute !important;
}

.underslice {
    position: absolute !important;
    z-index: inherit !important
}

.textDrawer {
    line-height: 1.4;
    font-size: 16;
    margin: 0;
    width: 144px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}