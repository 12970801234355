.imageArea {
  grid-area: image;
  border-radius: 16px;
  width: 216px;
  height: 216px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

}

.InputField {
  width: 100%;
  margin: 0
}

.ProfileModaleImage {
  grid-area: image;
  border-radius: 16px;
  width: 88px;
  height: 88px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  top: -10px
}

.buttonColor {
  color: #1A1A1E;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.80px;
  padding: 0;
}

.buttonColor:hover {
  color: #149C84;
}

.card:hover .imageChangeButton {
  visibility: visible;
}

.imageChangeButton {
  visibility: hidden;
  bottom: 40px;
}

.imageChangeButton:hover {
  visibility: visible;
  bottom: 40px;
}