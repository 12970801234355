.Liststyle {
    max-height: 350px;
    overflow-y: auto;
}

::-webkit-scrollbar-thumb {
    background-color: #888;
}

::-webkit-scrollbar-track {
    width: 1px;
    background-color: #f1f1f1;
}