.root {
  all: unset;
  position: relative;
  display: flex;
  flex-direction: row;
  background: #eee;
  height: 87vh;
  line-height: 1.5;
  letter-spacing: 0;
}

.headerButton {
  flex-direction: column;
  border-radius: 16px;
  font-size: 12px;
  line-height: 15.6px;
  font-weight: 500;
  font-family: 'Golos Text';
}

.title {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 500;
}

.wrapper {
  flex: 1;
  background: #F0F0F0;

}

.tree {
  background: #fff;
  border: 5px solid #ddd;
  border-radius: 4px;
  background: salmon;
}

.staticPosition {
  position: absolute !important;

}

.textDrawer {
  padding: 2px;
  width: 144px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.underslice {
  position: absolute !important;
  z-index: inherit !important
}