.root {
    all: unset;
    position: relative;
    display: flex;
    background: #eee;
    height: 87vh;
}

.sideBarImageArea {
    grid-area: image;
    border-radius: 16px;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

}


.birthArea {
    font-family: Golos Text;
    color: #7F7F7F;
    grid-area: birth;
    font-size: 90%;
    line-height: 130%;
    margin: 8px 0px 0px;
}



.relationAreaForDead {
    font-family: Golos Text;
    color: #7F7F7F;
    grid-area: birth;
    font-size: 90%;
    margin: 4px 0px 0px 0px;
    line-height: 130%;
}

.textDrawer {
    font-weight: 500;
    font-family: Gilroy;
    line-height: 130%;
    font-size: 130%;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dividerstyle {}

@media (max-width: 844px) {
    .dividerstyle {
        display: none
            /* Изменение на одну колонку при ширине блока менее 600px */
    }
}