.root {
    all: unset;
    position: relative;
    display: flex;
    background: #eee;
    height: 87vh;
}

.sideBarImageArea {
    grid-area: image;
    border-radius: 16px;
    width: 60px;
    height: 60px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

}


.birthArea {
    font-family: Golos Text;
    color: #7F7F7F;
    grid-area: birth;
    font-size: 10px;
    margin: 8px 0px 0px;
}

.birthAreaForDead {
    font-family: Golos Text;
    color: #7F7F7F;
    grid-area: birth;
    font-size: 10px;
    margin: 4px 0px 16px;
}

.relationAreaForDead {
    font-family: Golos Text;
    color: #7F7F7F;
    grid-area: birth;
    font-size: 10px;
    margin: 4px 0px 0px 0px;
    line-height: 15.6px;
}

.textDrawer {
    line-height: 15.6px;
    font-size: 14px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
}