.root {
  all: unset;
  position: absolute;
  margin-left: 125px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.staticPosition {
  position: static !important;
}

.border {
  border-right: 1px solid rgb(224, 224, 224);
}

.cardClicked {
  letter-spacing: 0;
  transition: .6s cubic-bezier(.3, 0, 0, 1.3) .1s;
  flex-direction: column;
  align-items: center;
  border: 2px solid #E5E5E5;
  text-align: center;
  background-color: #FFFFFF;
  border-radius: 24px;
  margin-top: 16px;
  padding: 16px;
  width: 180px;
  height: 279px;
  border-color: #149C84;
  border-width: 1px;
  gap: 14px;
  display: flex;
  overflow: hidden;
}

.cardClicked:hover {
  height: 375px;
  transition: .6s cubic-bezier(.3, 0, 0, 1.3);
}

.cardClicked:hover .imageArea {
  transition: .6s cubic-bezier(.3, 0, 0, 1.3);
}

.cardClicked:hover .AddDelete {
  opacity: 1;
  transition: .4s cubic-bezier(.3, 0, 0, 1.3) .4s;
  display: inline;
}

.cardClicked:hover .imageButton {
  transition: .6s cubic-bezier(.3, 0, 0, 1.3);
  display: inline;
}

.cardClicked:hover .imageChangeButton {
  visibility: visible;
}


.card2Clicked {
  letter-spacing: 0;
  transition: .6s cubic-bezier(.3, 0, 0, 1.3) .1s;
  flex-direction: column;
  align-items: center;
  border: 2px solid #E5E5E5;
  text-align: center;
  background-color: #FFFFFF;
  border-radius: 24px;
  margin-top: 16px;
  padding: 16px;
  width: 180px;
  height: 279px;
  border-color: #149C84;
  border-width: 1px;
  gap: 9px;
  display: flex;
  overflow: hidden;
}

.card2Clicked:hover {
  height: 400px;
  transition: .6s cubic-bezier(.3, 0, 0, 1.3);
}

.card2Clicked:hover .imageArea {
  transition: .6s cubic-bezier(.3, 0, 0, 1.3);
}


.card2Clicked:hover .AddDelete {
  opacity: 1;
  transition: .4s cubic-bezier(.3, 0, 0, 1.3) .4s;
  display: inline;
}

.card2Clicked:hover .imageButton {
  transition: .6s cubic-bezier(.3, 0, 0, 1.3);
  display: inline;
}

.card2Clicked:hover .imageChangeButton {
  visibility: visible;
}

.card3Clicked {
  letter-spacing: 0;
  transition: .6s cubic-bezier(.3, 0, 0, 1.3) .1s;
  flex-direction: column;
  align-items: center;
  border: 2px solid #E5E5E5;
  text-align: center;
  background-color: #FFFFFF;
  border-radius: 24px;
  margin-top: 16px;
  padding: 16px;
  width: 180px;
  height: 299px;
  border-color: #149C84;
  border-width: 1px;
  gap: 10px;
  display: flex;
  overflow: hidden;
}

.card3Clicked:hover {
  height: 420px;
  transition: .6s cubic-bezier(.3, 0, 0, 1.3);
}

.card3Clicked:hover .imageArea {
  transition: .6s cubic-bezier(.3, 0, 0, 1.3);
  margin-top: 6px;
}

.card:hover .imageButton {
  transition: .6s cubic-bezier(.3, 0, 0, 1.3);
  display: inline;
}

.card3Clicked:hover .AddDelete {
  opacity: 1;
  transition: .4s cubic-bezier(.3, 0, 0, 1.3) .4s;
  /* display: inline; */
}

.card3Clicked:hover .imageButton {
  transition: .6s cubic-bezier(.3, 0, 0, 1.3);
  display: inline;
}

.card3Clicked:hover .imageChangeButton {
  visibility: visible;
}

.card3 {
  transition: .6s cubic-bezier(.3, 0, 0, 1.3) .1s;
  flex-direction: column;
  align-items: center;
  border: 1px solid #E5E5E5;
  text-align: center;
  background-color: #FFFFFF;
  border-radius: 24px;
  margin-top: 16px;
  padding: 16px;
  width: 180px;
  height: 299px;
  gap: 10px;
  display: flex;
  overflow: hidden;
}

.card2 {
  transition: .6s cubic-bezier(.3, 0, 0, 1.3) .1s;
  flex-direction: column;
  align-items: center;
  border: 1px solid #E5E5E5;
  text-align: center;
  background-color: #FFFFFF;
  border-radius: 24px;
  margin-top: 16px;
  padding: 16px;
  width: 180px;
  height: 279px;
  gap: 10px;
  display: flex;
  overflow: hidden;
}

.card {
  transition: .6s cubic-bezier(.3, 0, 0, 1.3) .1s;
  flex-direction: column;
  align-items: center;
  border: 1px solid #E5E5E5;
  text-align: center;
  background-color: #FFFFFF;
  border-radius: 24px;
  margin-top: 16px;
  padding: 16px;
  width: 180px;
  height: 279px;
  gap: 14.3px;
  display: flex;
  overflow: hidden;
}

.infoP {
  margin-top: 0;
}

.AddDelete {
  transition: .3s cubic-bezier(.3, 0, 0, 1.3);
  opacity: 0;
  /* display: none; */
}

.card:hover .imageButton {
  display: flex;
}

.card2:hover .imageButton {
  display: flex;
}

.card3:hover .imageButton {
  display: flex;
}

.card3:hover {
  height: 438px;
  transition: .6s cubic-bezier(.3, 0, 0, 1.3);
}

.card3:hover .imageArea {
  transition: .6s cubic-bezier(.3, 0, 0, 1.3);
}

.card3:hover .AddDelete {
  opacity: 1;
  transition: .4s cubic-bezier(.3, 0, 0, 1.3) .4s;
  /* display: inline; */
}

.card3:hover .imageChangeButton {
  visibility: visible;
}

.card2:hover {
  height: 400px;
  transition: .6s cubic-bezier(.3, 0, 0, 1.3);
}



.card2:hover .imageArea {
  transition: .6s cubic-bezier(.3, 0, 0, 1.3);
}

.card2:hover .AddDelete {
  opacity: 1;
  transition: .4s cubic-bezier(.3, 0, 0, 1.3) .4s;
}

.card2:hover .imageChangeButton {
  visibility: visible;
}

.card2:hover .imageButton {
  transition: .6s cubic-bezier(.3, 0, 0, 1.3);

  display: inline;
}

.card:hover {
  height: 375px;
  transition: .6s cubic-bezier(.3, 0, 0, 1.3);
}

.card:hover .imageArea {
  transition: .6s cubic-bezier(.3, 0, 0, 1.3);
  /* margin-top: 6px; */
}

.card:hover .AddDelete {
  opacity: 1;
  transition: .4s cubic-bezier(.3, 0, 0, 1.3) .4s;
  /* display: inline; */
}

.phantomInner:hover {
  transition: 1s cubic-bezier(.3, 0, 0, 1.3);
  box-shadow: 0px 4px 20px 0px rgba(6, 165, 127, 0.45);
  border: 1px solid white;
  color: #149C84;
}

.phantomInnerForPra:hover {
  transition: 1s cubic-bezier(.3, 0, 0, 1.3);
  box-shadow: 0px 4px 20px 0px rgba(6, 165, 127, 0.45);
  border: 1px solid white;
  color: #149C84;
}

/*
.imageButton:hover {
  display: flex;
}
*/
.card:hover .imageChangeButton {
  visibility: visible;
}

.root .card:hover .inner1 .imageArea .imageLink {
  display: flex;
}

.inner2 {
  position: relative;
  display: grid;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 24px;
  border: 2px solid #E5E5E5;
  transition: 1s;
  transform: translateY(-50px);
}

.phantomRoot {
  all: unset;

  position: absolute;
  margin-left: 110px;
  display: flex;
  padding: 16px;
  padding-top: 200px;
  align-items: center;
  justify-content: center;

}

.inner {
  display: grid;
  flex: 1;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 24px;
  height: auto;
  align-items: center;
  justify-content: center;
  justify-items: center;
  text-align: center;
  background: white;
  grid-template-areas:
    "image"
    "image"
    "nameText"
    "SnameText"
    "MnameText"
    "birth";
  grid-template-rows: 90px 90px 19px 19px 25px 30px;

}

.unnownUserInner1 {
  display: grid;
  flex: 1;
  border: 2px solid #E5E5E5;
  border-radius: 24px;
  align-items: center;
  justify-content: center;
  justify-items: center;
  text-align: center;
  background: white;
  z-index: 1;
  box-sizing: border-box !important;
  width: 212px;
  height: 312px;
  margin-top: 16px;
  grid-template-areas:
    "image"
    "image"
    "nameText";
  grid-template-rows: 80px 138px 70px;
}

.phantomInner {
  box-sizing: border-box;
  transition: 1s cubic-bezier(.3, 0, 0, 1.3);
  cursor: pointer;
  display: grid;
  flex: 1;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 24px;
  align-items: center;
  justify-content: center;
  justify-items: center;
  text-align: center;
  background: white;
  max-width: 216px;
  max-height: 100px;
  width: 216px;
  height: 94px;
  padding: 16px;
  grid-template-areas:
    "image"
    "nameText"
}

.phantomInnerForPra {
  transition: 1s cubic-bezier(.3, 0, 0, 1.3);
  cursor: pointer;
  display: grid;
  flex: 1;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 24px;
  align-items: center;
  justify-content: center;
  justify-items: center;
  text-align: center;
  background: white;
  max-width: 216px;
  width: 216px;
  height: 94px;
  padding: 10px 16px 10px 16px;
  grid-template-areas:
    "image"
    "nameText"
}


.headerButton {
  flex-direction: column;
  border-radius: 16px;
  font-size: 12px;
  line-height: 15.6px;
  font-weight: 500;
  font-family: 'Golos Text';
}

.defaultImage {
  grid-area: image;
  margin: 16px;
  border-radius: 16px;
  width: 180px;
  height: 180px;
}

.sideBarImageArea {
  grid-area: image;
  border-radius: 16px;
  width: 88px;
  height: 88px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

}

.imageArea {
  grid-area: image;
  border-radius: 16px;
  width: 180px;
  height: 180px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  top: -10px;
}

.phantomImageArea {
  grid-area: image;
  margin: 4px;
  margin-top: 20.5px;
  border-radius: 16px;
  width: 24px;
  height: 24px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.textName {
  font-family: Golos Text;
  grid-area: nameText;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  padding-bottom: 2px;
  padding-top: 3px;
  margin: 0px;
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.unnownUserTextName {
  font-family: Golos Text;
  grid-area: nameText;
  font-size: 16px;

  width: 150px;
}

.textSName {
  font-family: Golos Text;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  margin: 0px;
  grid-area: SnameText;
  font-size: 16px;
  padding-bottom: 3px;
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

.textMName {
  font-family: Golos Text;
  font-weight: 400;
  line-height: 130%;
  margin: 0px;
  grid-area: MnameText;
  font-size: 16px;
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.phantomTextName {
  font-family: Golos Text;

  grid-area: nameText;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  margin: 4px 0 0 0;
}


.birthArea {
  font-family: Golos Text;
  color: #7F7F7F;
  grid-area: birth;
  font-size: 12px;
  margin: 4px 0px 0 0;
}

.birthAreaForDead {
  font-family: Golos Text;
  color: #7F7F7F;
  grid-area: birth;
  font-size: 12px;
  margin: 4px 0 0 0;
}

.relationAreaForDead {
  font-family: Golos Text;
  box-sizing: border-box;
  color: #7F7F7F;
  grid-area: birth;
  font-size: 12px;
  margin: 4px 0px 0px 0px;
}

.imageButton {
  line-height: 140%;
  font-size: 12px;
  font-style: normal;
  font-family: Golos Text;
  font-weight: 500;
  text-align: center;
  justify-content: center;
  background-color: white;
  /* width: 208px;
  height:70px; */
  /* border: 2px solid #E5E5E5; */
  /* margin-bottom: 16px; */
  color: #149C84;
  text-decoration: none;
  border: 0px;
  display: none;
  cursor: pointer;

  /* transform: translateY(0px); */
}

.imageChangeButton {
  visibility: hidden;
  bottom: 40px;
}

.drawerTextName {
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}